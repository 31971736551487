$( window ).on( "load", function() {
	// tabs
	$( ".services-video__list" ).on( "mouseenter", ".services-video__list--item:not(.active)", function() { 
		$( this )
			.addClass( "active" ).siblings().removeClass( "active" )
			.closest( ".services-video" ).find( ".services-video__video--tab" )
			.removeClass( "active" ).eq( $( this ).index() ).addClass( "active" );
	
		var video = $( ".services-video__video--tab.active video" )[ 0 ];
		if ( video ) {
			video.play();
		}
	} );

	$( ".services-video__list" ).on( "mouseleave", ".services-video__list--item", function() {
		var video = $( ".services-video__video--tab.active video" )[ 0] ;
		if ( video ) {
			video.pause();
			video.currentTime = 0;
		}
	} );
	
} );
